<template>
  <section>
    <Side-bar-new-expend
      :is-add-new-expend-sidebar-active.sync="isAddNewExpendSidebarActive"
      @clicked="one"
    />
    <Side-bar-exist-expend
      :is-exist-expend-sidebar-active.sync="isExistExpendSidebarActive"
      :selected="selected"
      @clicked="two"
    />
    <b-row class="match-height">
        <b-col lg="12">
          <!--  <ecommerce-revenue-report :data="data.revenue"/> -->
        </b-col>
        <!--/ Revenue Report Card -->

          <b-col
            cols="12"
            md="2"
            class="mb-1 mb-md-0"
          >
          <b-button
            variant="primary"
            class="mb-1"
            @click="isAddNewExpendSidebarActive = true"
          >
            <span class="text-nowrap">Add</span>
          </b-button>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-1 mb-md-0"
          >
        </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <flat-pickr
              v-model="rangeDate"
              class="form-control mb-1 mr-1"
              :config="{ mode: 'range'}"
            />
              <b-button
                variant="primary"
                class="mb-1"
                @click="exportToExcel()"
              >
                <span class="text-nowrap">Export</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
        <!-- table -->
        <b-table
          ref="selectableTable"
          selectable
          :select-mode="selectMode"
          :items="items"
          :fields="fields"
          responsive
          striped
          class="mb-0"
          @row-selected="onRowSelected"
        >
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'apps-Member-view', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item :to="{ name: 'apps-Member-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>

              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <template #cell(status_number)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
        <b-row>
          <b-col class="mt-1">
            Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }}
          </b-col>
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              align="right"
              size="md"
              class="my-0"
              @change="handleChangePage"
            />
          </b-col>

    </b-row>
  </section>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'


import {
  BTable, BAvatar, BCardBody, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend,BModal, VBModal} from 'bootstrap-vue'
import SideBarNewExpend from './SideBarNewExpend.vue'
import SideBarExistExpend from './SideBarExistExpend.vue'
import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
import moment from 'moment'
export default {
  components: {
    SideBarNewExpend,
    SideBarExistExpend,
    flatPickr,
    moment,
    BTable,
    BModal,
    VBModal,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
    BRow,
    BCol,
    EcommerceRevenueReport,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      isAddNewExpendSidebarActive : false,
      isExistExpendSidebarActive : false,
      data : null,
      iframe_src : '//cloudfit.pro/invoice.php',
      rangeDate: null,
      test_string : null,
      headerTitle: ['Date', 'Visa','Master', 'FPS', 'Payme', 'Cash','Daily Total'],
      headerVal: ['date', 'visa','master', 'fps', 'payme', 'cash','total'],
      genderOptions: [
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
      centerOptions: [],
      isAddNewExpendSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [{ key: 'id', label: '#' }, { key: 'expend_date', label: 'Date' },{ key: 'staff_name', label: 'Staff' }, { key: 'amount', label: 'Amount' }, { key: 'expend_type', label: 'Type' },{key:'expend_to',label: 'To'}, { key: 'note', label: 'Note' }],
      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'Active', 2: 'Disabled', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  watch: {
    rangeDate : {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.searchString = newValue.toUpperCase()
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
    this.get_center_data()
    this.$http.get('/ecommerce/data')
      .then(response => {
        console.log(response.data)
        this.data = response.data
      })
  },
  methods: {
    one() {
      this.isAddNewExpendSidebarActive = false
      this.get_data()
    },
    two() {
      this.isExistExpendSidebarActive = false
      this.get_data()
    },
    void_transaction() {
      this.$http.post(process.env.VUE_APP_API_BASE+'/expend/'+this.selected.id+'/delete/',{})
        .then(res => {
          this.get_data()
          this.get_center_data()
          this.$refs['my-modal'].hide()
        })
    },
    exportToExcel() {
      console.log(this.rangeDate)
      import('@/@core/Export2Excel').then(excel => {
        const list = this.items
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: 'daily_sales',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.clearFields()
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          // Add col name which needs to be translated
          // if (j === 'timestamp') {
          //   return parseTime(v[j])
          // } else {
          //   return v[j]
          // }

          return v[j]
        })
      )
    },
    get_data() {
      var splitted = []
      if (this.rangeDate === null) {
        splitted[0] = null
        splitted[1] = null
        this.rangeDate = moment().subtract(7, 'days').format('YYYY-MM-DD') + " to " + moment().format("YYYY-MM-DD")
      } else {
        splitted = this.rangeDate.split(" to ");
      }
      this.$http.get(process.env.VUE_APP_API_BASE+`/expend/`+this.$store.getters['center/currentCenterId'] + `/?from=`+splitted[0]+`&to=`+splitted[1])
        .then(res => {
          this.items = res.data
            console.log( res.data )
        })
    },
    get_center_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/centers/')
        .then(res => {
          this.centerOptions = res.data.centers
        })
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items[0]
      //console.log(items)
      this.isExistExpendSidebarActive = true
    },
    close() {
      this.iframe_src = '//www.cloudfit.pro/invoice.php'
      this.$refs['my-modal'].hide()
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}

</style>
