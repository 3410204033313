<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewExpendSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false);$refs.simpleRules.reset();userData=blankUserData"
    @change="(val) => $emit('one')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          New Expend
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        ref="simpleRules"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

        <validation-provider
            #default="validationContext"
            name="Birth"

          >

            <b-form-group
              label="Date"
              label-for="Date"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="userData.date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Amount"
            rules=""
          >
            <b-form-group
              label="Amount"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="userData.amount"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Type"
          >
            <b-form-group
              label="Type"
              label-for="Type"
            >
              <b-form-input
                id="Type"
                v-model="userData.type"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>







          <validation-provider
            #default="validationContext"
            name="To"
          >
            <b-form-group
              label="To"
              label-for="To"
            >
              <b-form-input
                id="To"
                v-model="userData.to"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
          #default="validationContext"
          name="Price"
        >
          <b-form-group
            label="Note"
            label-for="Note"
          >
            <b-form-textarea
              id="Note"
              v-model="userData.note"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-form-group
          label="Attach File(s)"
          label-for="Attach"
        >
        <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-model="userData.file"
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="submit_now()"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    ToastificationContent,
    BFormTextarea,
    BFormFile,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewExpendSidebarActive',
    event: 'update:is-add-new-Expend-sidebar-active',
  },
  props: {
    isAddNewExpendSidebarActive: {
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      blankUserData: {
        date: '',
        amount: '',
        type: '',
        to: 'admin',
        note: '',
      },
    }
  },
  methods: {

    resetForm() {

    },
    submit_now() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          var formData = new FormData();
        //  var imagefile = this.userData.file
          formData.append("date", this.userData.date);
          formData.append("amount", this.userData.amount);
          formData.append("type", this.userData.type);
          formData.append("to", this.userData.to);
          formData.append("note", this.userData.note);
          formData.append("file", this.userData.file);

          this.$http.post(process.env.VUE_APP_API_BASE+'/expend/'+this.$store.getters['center/currentCenterId']+'/add/', formData , {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
            .then(res => {
              if (res.data.result === true) {
                this.$emit('clicked', false)
                this.userData = this.blankUserData
                this.$refs.simpleRules.reset()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })
        }
      })
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      display_name: '',
      username: '',
      password: '',
      role: 'admin',
      status: 'active',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {

    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
