<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isExistExpendSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="$emit('clicked',false);$refs.simpleRules.reset();userData=blankUserData"
    @change="(val) => $emit('one')"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Expend
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >


            <b-form-group
              label="Date"
              label-for="Date"

            >
              <flat-pickr
                v-model="selected.expend_date"
                class="form-control"
                :config="{ dateFormat: 'Y-m-d'}"
              />

            </b-form-group>


          <!-- Username -->

            <b-form-group
              label="Amount"
              label-for="amount"
            >
              <b-form-input
                id="amount"
                v-model="selected.amount"

                trim
              />


            </b-form-group>



            <b-form-group
              label="Type"
              label-for="Type"
            >
              <b-form-input
                id="Type"
                v-model="selected.expend_type"

                trim
              />


            </b-form-group>


            <b-form-group
              label="To"
              label-for="To"
            >
              <b-form-input
                id="To"
                v-model="selected.expend_to"

                trim
              />


            </b-form-group>


          <b-form-group
            label="Note"
            label-for="Note"
          >
            <b-form-textarea
              id="Note"
              v-model="selected.note"
              trim
            />


          </b-form-group>

        <b-form-group
          v-if="!selected.attachment"
          label="Attach File(s)"
          label-for="Attach"
        >
        <b-form-file
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              v-model="newfile"
            />
          </b-form-group>

          <b-form-group
            v-if="selected.attachment"
            label="Attach File(s)"
            label-for="Attach"
          >
          <b-button
            type="button"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-warning"
            @click="download(selected.attachment)">
            Download Attached File
          </b-button>
            </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="button"
              @click="submit_now()"
            >
              Update
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                class="mr-2"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              Delete
            </b-button>
          </div>

        </b-form>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import countries from '@/@fake-db/data/other/countries'

export default {
  components: {
    ToastificationContent,
    BFormTextarea,
    BFormFile,
    flatPickr,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isExistExpendSidebarActive',
    event: 'update:is-exist-Expend-sidebar-active',
  },
  props: {
    isExistExpendSidebarActive: {
      type: Boolean,
      required: true,
    },
    selected: {
      required: false
    }

  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      newfile:null,
      blankUserData: {
        date: '',
        amount: '',
        type: '',
        to: 'admin',
        note: '',
      },
    }
  },
  created() {

  },
  methods: {
    download (file) {
      window.location.href = "https://www.cloudfit.pro/"+file;
    },
    submit_now() {


          var formData = new FormData();
        //  var imagefile = this.userData.file
          formData.append("date", this.selected.expend_date);
          formData.append("amount", this.selected.amount);
          formData.append("type", this.selected.expend_type);
          formData.append("to", this.selected.expend_to);
          formData.append("note", this.selected.note);
          formData.append("file", this.newfile);

          this.$http.post(process.env.VUE_APP_API_BASE+'/expend/edit/'+this.selected.id+'/', formData , {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
            .then(res => {
              if (res.data.result === true) {
                this.$emit('clicked', false)
                this.userData = this.blankUserData
                this.$refs.simpleRules.reset()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Error',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: res.data.message,
                  },
                })
              }
            })


    },
  },





}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
